<div>
  <label class="form-label input-title">
    {{ inputTitle! | translate }}

    <ng-content select="label"></ng-content>

    <span *ngIf="requiredFl || isRequired()" class="required-mark font-body">
      *
    </span>
  </label>

  <div
    class="input-content input-group"
    [class.has-validation]="hasValidator()"
    [ngStyle]="{ gap }"
  >
    <ng-content></ng-content>

    <div
      class="form-text error-text invalid-feedback"
      *ngIf="errorText || hasValidator()"
      [class.invalid]="errorText || isInvalid()"
      [class.validated]="errorText"
    >
      {{ errorText }}
      <ng-content select="[error]"></ng-content>
    </div>
  </div>

  <div class="form-text notice-text font-caption" *ngIf="noticeText">
    {{ noticeText }}
  </div>
</div>
