import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * 정보입력 컴포넌트
 * invalid 메세지 처리
 */
@Component({
  selector: 'trnty-input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputWrapperComponent implements OnInit, AfterContentInit {
  @ContentChildren(NgControl)
  controls?: QueryList<NgControl>;

  /** 오류 문구 */
  @Input() errorText?: string;

  /** 안내사항 문구 */
  @Input() noticeText?: string;

  /** 입력 항목이름 */
  @Input() inputTitle?: string;

  /** 입력 필수 여부 */
  @Input() requiredFl?: boolean;

  /** input 아이템 간 간격 */
  @Input() gap = '0px';

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    const inputList = this.elementRef.nativeElement.querySelectorAll('input');
    inputList.forEach((input) => {
      // Bootstrap 클래스 등록
      if (input.type === 'checkbox' || input.type === 'radio') {
        input.classList.add('form-check-input');
      } else {
        input.classList.add('form-control');
      }
    });

    const selectList = this.elementRef.nativeElement.querySelectorAll('select');
    selectList.forEach((select) => {
      // Bootstrap 클래스 등록
      select.classList.add('form-select');
    });
  }

  isRequired(): boolean {
    if (this.controls == null) {
      return false;
    }
    return this.controls.some((control) => {
      return (
        (<ElementRef<any>>(<any>control.valueAccessor)?._elementRef)
          ?.nativeElement?.required ?? false
      );
    });
  }

  hasValidator(): boolean {
    return (
      this.controls!
        .map((control) => {
          return control?.control?.validator;
        })
        .filter((fn) => !!fn).length > 0
    );
  }

  isInvalid(): boolean {
    if (this.controls == null) {
      return false;
    }

    const invalid = this.controls.some((form) => {
      if (form.invalid && (form.dirty || form.touched)) {
        return true;
      }
      return false;
    });

    this.getControlElementList().forEach((element) => {
      if (!element) {
        return;
      }

      if (invalid) {
        // 상태에 따라 Bootstrap 클래스 추가/제거
        element.classList.add('is-invalid');
      } else {
        element.classList.remove('is-invalid');
      }
    });

    return invalid;
  }

  private getControlElement(control: NgControl): HTMLElement {
    const accessor = <{ _elementRef?: ElementRef<HTMLInputElement> }>(
      control.valueAccessor
    );

    return accessor?._elementRef?.nativeElement!;
  }

  private getControlElementList(): HTMLElement[] {
    return this.controls?.map(this.getControlElement)!;
  }
}
