import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { InputWrapperComponent } from './input-wrapper.component';

@NgModule({
  declarations: [InputWrapperComponent],
  imports: [CommonModule, SharedModule, FormsModule],
  exports: [InputWrapperComponent],
})
export class InputWrapperModule {}
